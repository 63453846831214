
.button-container {
  margin-bottom: 20px;
}


.info {
  text-align: center;
  margin: auto;
  max-width: 600px;
 
}


.network-map img,
.list-map img {
  width: 100%;
  max-width: 800px;
  
  
}

/* .network-map {
  border: 1px solid red;
} */

.main-content {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: row;
  margin-left: 2.5rem;
  
}

.sidebar {
  background: linear-gradient(#053257, #7e7e7e);
  padding: 30px 20px;
  width: 300px;
  height: 100%;
}

.sidebar input:not([type='file']),
.sidebar select,
.sidebar .button {
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.sidebar button:hover {
  background-color: #0056b3;
}

.topButton {
  display: block;
  width: 150px;
  margin-bottom: 10px;
  padding: 8px;
  background-color: #e0e1ff;
  color: black;
  cursor: default;
  border: 2px solid black;
  
}

/*uncommented out width*/
.content {
  width: calc(100vw - 300px);
  padding: 0px 20px;
}

h2 {
  font-size: 2em;
  font-weight: bold;
  margin: 0 0 0 0;
  line-height: 125%;
}

.list-map > div > a,
.list-map > div > p {
  margin-top: 0.3rem;
}

.list-map > div > a {
  text-decoration: underline;
  display: block;
}

.ror {
  color: #0000ee;
  font-weight: bold;
}

.dep-content {
  margin-left: 6rem;
}

#cyto {
  height: 400px;
}

/* Media query for screens smaller than 600px */
/* Change max-width to 768px, standardizing the layout from phones to tablets*/
/* adjusted margin top */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    margin-left: 0rem;
    margin-top: .5rem;
   
  }
   


  .sidebar {
    width: 100%;
  }

  .content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .dep-content {
    margin-left: 0px;
    margin-top: 15px;
  }

  h2 {
    font-size: 1.5em;
  }

  .main-content p,
  .main-content a,
  .main-content input,
  .main-content button,
  .main-content select {
    font-size: 13px;
  }

  #cyto {
    height: 150px;
  }
  
}

